import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import Rightbar from "../../layouts/maidManagementLayout/rightbar"
import "./maidTableHeader.css"
import swal from "sweetalert"
import DatePicker from "react-datepicker"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const HeaderTable = styled.div`
  text-align: left;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`
class MaidCheckOtaPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.maidColumn,
      columnMaidProfile: this.props.columnMaidProfile,
      resAnnouncement: [],
      data: [],
      totaldata: 0,
      pages: 0,
      checktext: "",
      textSearch: "",
      tempDataTable: [],
      datetime: new Date(localStorage.getItem("datetime_monthly") || new Date()),
      loading: false,
    }
  }

  componentDidMount() {
    this.props.setPageTitle("Check who got OTA")
    this.onFeactData(1, false)
    // this.props.showBackIcon(true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.column !== this.props.maidColumn ||
      prevState.columnMaidProfile !== this.props.columnMaidProfile
    ) {
      this.setState({
        column: this.props.maidColumn,
        columnMaidProfile: this.props.columnMaidProfile,
      })
      this.props.setPageTitle("Check who got OTA")
    }
  }

  formatNumber(num) {
    var val = (Number(num) * 100) / 100
    var parts = val.toString().split(".")
    var num1 =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1].substr(0, 2) : ".00")
    return num1
  }

  async onFeactData(page, swalert = true) {
    const booking_id = this.state.textSearch.replace(/\D/g, "")
    if (booking_id === "") {
      if (swalert) {
        swal("Please enter the Internal ID", {
          icon: "warning",
        })
      }
      return
    }

    this.setState({ loading: true, tempDataTable: [] })
    try {
      request
        .get(`/booking/ota/log/${booking_id}`)
        .then((res) => {
          let resp = res.data
          console.log(`: ------------------------------------------`)
          console.log(`MaidCheckOtaPage -> onFeactData -> resp`, resp)
          console.log(`: ------------------------------------------`)

          let tempArr = []
          if (resp.maid_ids.length > 0) {
            for (let i = 0; i < resp.maid_ids.length; i++) {
              if (resp.maid_ids[i]) {
                tempArr.push({
                  App_ID: resp.maid_ids[i] ? parseInt(resp.maid_ids[i].id) : 0,
                  Maid_ID: resp.maid_ids[i] ? parseInt(resp.maid_ids[i].real_maid_id) : 0,
                  // name: maids.first_name + " " + maids.last_name,
                })
              }
            }

            tempArr.sort((a, b) => b.Maid_ID - a.Maid_ID)

            this.setState(
              {
                data: tempArr,
                loading: false,
                totaldata: resp.maid_ids.length,
                pages: 1,
              },
              () => this.filterdataList(tempArr)
            )
          } else {
            alert("Not found maid")
            this.setState({ data: [], loading: false, totaldata: 0, pages: 0 })
          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          console.log(`: ------------------------------------------`)
          console.log(`MaidCheckOtaPage -> onFeactData -> error`, error)
          console.log(`: ------------------------------------------`)
          if (error.response) {
            alert(error.response.data.message)
          } else {
            alert(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      console.log(`: ------------------------------------------`)
      console.log(`MaidCheckOtaPage -> onFeactData -> error`, error)
      console.log(`: ------------------------------------------`)

      if (error.response) {
        alert(error.response.data.message)
      } else {
        alert(error.message)
      }
    }
  }

  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }

  onChangeTab = (path) => {
    const { history, match } = this.props
    history.push(match.url + path)
  }
  onSentTextSearch = () => {
    if (this.state.textSearch !== "") {
      this.setState({ checktext: this.state.textSearch }, () => this.onFeactData())
    } else if (this.state.textSearch === "" && this.state.checktext !== "") {
      this.setState({ checktext: "clearSearch" }, () => this.onFeactData())
    } else {
      this.onFeactData()
    }
  }

  compareBy(key, order) {
    return function (a, b) {
      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  async togleSorting(newSorted, column, shiftKey) {
    let arrayCopy =
      (await this.state.tempDataTable.length) > 0
        ? [...this.state.tempDataTable]
        : [...this.state.data]
    arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy, tempDataTable: arrayCopy })
  }

  async filterdataList(data) {
    let arr = []
    if (this.props.averageListFilter.length === 0 || this.props.repeatRateListFilter.length === 0) {
      arr = ["noData"]
    } else {
      arr = await data.filter(
        (v) =>
          this.checkFilterAverRageList(v.Average_Ratings) &&
          this.checkFilterRepeateRateList(v.Repeat_Rate) &&
          this.checkFilterPublishedList(v.Is_Published)
      )
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this._on_data_filter(arr)
  }
  checkFilterAverRageList(averageRatings) {
    if (averageRatings >= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 1) > -1
    } else if (averageRatings >= 4.7 && averageRatings <= 4.85) {
      return this.props.averageListFilter.findIndex((e) => e.id === 2) > -1
    } else if (averageRatings >= 4.55 && averageRatings <= 4.7) {
      return this.props.averageListFilter.findIndex((e) => e.id === 3) > -1
    } else if (averageRatings >= 4.1 && averageRatings <= 4.55) {
      return this.props.averageListFilter.findIndex((e) => e.id === 4) > -1
    } else if (averageRatings >= 3.95 && averageRatings <= 4.1) {
      return this.props.averageListFilter.findIndex((e) => e.id === 5) > -1
    } else {
      return this.props.averageListFilter.findIndex((e) => e.id === 6) > -1
    }
  }
  checkFilterRepeateRateList(repeatRate) {
    if (repeatRate >= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 1) > -1
    } else if (repeatRate >= 80 && repeatRate <= 90) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 2) > -1
    } else if (repeatRate >= 70 && repeatRate <= 80) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 3) > -1
    } else if (repeatRate >= 60 && repeatRate <= 70) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 4) > -1
    } else if (repeatRate >= 50 && repeatRate <= 60) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 5) > -1
    } else if (repeatRate >= 40 && repeatRate <= 50) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 6) > -1
    } else if (repeatRate >= 30 && repeatRate <= 40) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 7) > -1
    } else if (repeatRate >= 20 && repeatRate <= 30) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 8) > -1
    } else if (repeatRate >= 10 && repeatRate <= 20) {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 9) > -1
    } else {
      return this.props.repeatRateListFilter.findIndex((e) => e.id === 10) > -1
    }
  }
  checkFilterPublishedList(is_published) {
    if (is_published) {
      return true
    }
    return this.props.publishedListFilter.findIndex((e) => e.id === 1) > -1
  }

  _on_data_filter(data) {
    this.setState({ tempDataTable: data })
  }
  openProfileMaid(data) {
    const url = `#/admin/maid-management/maidProfile/${data.App_ID}`
    const state = data
    window.open(url, state)
  }

  render() {
    const allColumns = [
      // {
      //   Header: () => <HeaderTable>App ID</HeaderTable>,
      //   accessor: "App_ID", // String-based value accessors!
      //   minWidth: 40,
      //   Cell: (row) => (
      //     <div className="maidAllTable">
      //       {row.original !== "noData"
      //         ? row.original.App_ID === -1
      //           ? "-"
      //           : row.original.App_ID
      //         : "-"}
      //     </div>
      //   ),
      // },
      {
        Header: () => <HeaderTable style={{ paddingLeft: 5 }}>Maid ID</HeaderTable>,
        accessor: "Maid_ID",
        Cell: (row) => (
          <div style={{ textAlign: "left", paddingLeft: 5 }}>{row.original.Maid_ID || "-"}</div>
        ),
        minWidth: 50,
      },
      // {
      //   Header: () => <HeaderTable>Maid Name</HeaderTable>,
      //   accessor: "name", // String-based value accessors!
      //   minWidth: 100,
      // },
    ]

    let columnWillShow = allColumns
    const tabData = [
      {
        label: "Maid List",
        total:
          this.state.tempDataTable.length > 0
            ? this.state.tempDataTable.length
            : this.state.totaldata,
        key: "0",
        path: "/maid",
      },
    ]
    const currentLocation = this.props.history.location.pathname

    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3 btn-container">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className=""
                  style={{
                    float: "right",
                    marginRight: "10px",
                    marginTop: "20px",
                    marginLeft: "15px",
                    width: "150px",
                  }}>
                  <label style={{ fontSize: "18px", fontWeight: "500px" }}>Internal ID :</label>
                </div>
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textMaild"
                  placeholder="Search.."
                  name="textSearch"
                  value={this.state.textSearch}
                  onChange={(e) => this.setState({ textSearch: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSentTextSearch()
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  className="search-containe-button"
                  onClick={this.onSentTextSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <TabComponent transparent style={{}} tabData={tabData} onChangeTab={this.onChangeTab} />
        <Card>
          <ReactTable
            data={this.state.tempDataTable.length > 0 ? this.state.tempDataTable : this.state.data}
            columns={columnWillShow} //column config object
            loading={this.state.loading}
            pageSize={25}
            page={this.state.page}
            showPagination={true}
            PaginationComponent={Pagination}
            pages={this.state.pages}
            manual
            onSortedChange={(newSorted, column, shiftKey) =>
              this.togleSorting(newSorted, column, shiftKey)
            }
            onPageChange={(page) =>
              this.setState({ page: page }, () => {
                this.onFeactData({ page: page + 1 })
              })
            }
            getTrGroupProps={(state, rowInfo) => {
              if (rowInfo !== undefined) {
                return {
                  onDoubleClick: () => {
                    this.openProfileMaid(rowInfo.original)
                  },
                }
              }
            }}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  height: 25,
                  overflow: "hidden",
                },
              }
            }}
            className="-striped -highlight maidListetable"
          />
        </Card>
        <Rightbar
          data={this.state}
          currentLocation={currentLocation}
          dataFilter={this.state.data}
          data_filter={(data) => this._on_data_filter(data)}
        />
        {this.props.children}
      </div>
    )
  }
}

const mapState = (state) => ({
  maidColumn: state.maid.column,
  columnMaidProfile: state.maid.columnMaidProfile,
  totalPageList: state.maid.totalPageList,
  averageListFilter: state.maid.averageListFilter,
  repeatRateListFilter: state.maid.repeatRateListFilter,
  publishedListFilter: state.maid.publishedListFilter,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    showBackIcon: dispatch.Title.showBackIcon,
    getMaidList: dispatch.maid.getMaidList,
  }
}

export default connect(mapState, mapDispatch)(MaidCheckOtaPage)
